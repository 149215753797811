/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* CSS for intro.js */
@import "../node_modules/intro.js/introjs.css";

/*CSS required for share buttons */
@import "~@angular/cdk/overlay-prebuilt.css";
@import "../node_modules/ngx-sharebuttons/themes/default.scss";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* ngx-toastr css */
@import "../node_modules/ngx-toastr/toastr.css";

.toast-container {
  z-index: 2147483647 !important;
}
.toast-info {
  background-image: none !important;
}

.toast-error {
  background-image: none !important;
}

.toast-success {
  background-image: none !important;
}

.toast-warning {
  background-image: none !important;
}
#login-modal-content {
  padding: 1em;

  div#login-content {
    padding: 4em;
    text-align: center;
  }

  h1 {
    letter-spacing: 1.1;
  }

  app-login-button {
    display: block;
    margin-top: 10%;
  }
}

.introjs-tooltip-title {
  color: black;
}

.introjs-tooltiptext {
  color: black;
}

div.title-area {
  text-align: center;
  background: url("https://v1.nitrocdn.com/fYEZdJKjvuXkKgFpeJrbqdViLvHKUQUB/assets/static/optimized/rev-3261ba5/wp-content/themes/norris/images/arrow.png")
    center bottom no-repeat;
  background-size: auto;
  -webkit-background-size: 600px 14px;
  -moz-background-size: 600px 14px;
  background-size: 600px 14px;
  padding-bottom: 20px;
  margin-bottom: 30px;
}

.d-flex {
  display: flex;
}

ion-button {
  font-weight: bold;
}

ion-menu-button {
  color: var(--ion-color-primary);
}

#no-items-row {
  h6 {
    padding-left: 20px;
    font-style: italic;
  }
}

nanogallery2 {
  width: 98%;
  height: 96%;
  margin: auto;
}

ion-select::part(text) {
  color: #3b7a8a !important;
}

.nanogallery-scroll-fix-fullscreen {
  overflow-y: auto;
  max-height: calc(100vh - 80px);
}

.nanogallery-scroll-fix-modal {
  overflow-y: auto;
  max-height: 92vh;
}

//hide scrollbar
.noscroll::-webkit-scrollbar {
  display: none;
}

// this class is used to make nanogallery audio file clickable (playable)
.clickable {
  pointer-events: all !important;
}

a:hover {
  cursor: pointer;
}

.loading-overlay {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.6;
  z-index: 9999999999;
}

.loading-spinner {
  z-index: 999999999;
  position: relative;
  width: 50px;
  height: 50px;
  border: 10px solid rgba(255, 255, 255, 1);
  border-radius: 50%;
  border-top-color: var(--ion-color-primary);
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  margin: auto;
  vertical-align: auto;
}

//removes whitespace from bottom of modal
ion-modal.auto-height {
  &.bottom {
    align-items: flex-end;
  }

  --height: auto;

  .ion-page {
    position: relative;
    display: block;
    contain: content;

    .inner-content {
      max-height: 80vh;
      overflow: auto;
      padding: 10px;
    }
  }
}

//removes whitespace from bottom of modal
ion-modal.share-modal {
  &.bottom {
    align-items: flex-end;
  }

  --height: auto;

  .ion-page {
    position: relative;
    display: block;
    contain: content;

    .inner-content {
      max-height: 80vh;
      overflow: auto;
      padding: 10px;
    }
  }
  --border-radius: 10px;
}

ion-modal.reminder-modal {
  &.bottom {
    align-items: flex-end;
  }

  --height: auto;

  .ion-page {
    position: relative;
    display: block;
    contain: content;

    .inner-content {
      max-height: 80vh;
      overflow: auto;
      padding: 10px;
    }
  }
  --border-radius: 10px;
}

.alert-wrapper {
  min-width: 500px !important;
}

.list-title {
  font-size: 1.25rem;
  //font-weight: bold;
}

.margin-left-small {
  margin-left: 0.25rem;
}

//apply shadow to gallery thumbnails on hover
.nGY2GThumbnailCustomLayer:hover::before {
  //content: "";
  // background: linear-gradient(
  //   0deg,
  //   rgba(40, 40, 40, 0) 70%,
  //   rgba(0, 0, 0, 0.3) 100%
  // );
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(120deg, #eaee44, #33d0ff);
  opacity: 0.7;
  // transition: opacity 200ms ease-in;
}

.popover-chevron-icon {
  color: #1a7d8c;
  margin-left: 0.25rem;
}

//styles for audio & document thumbs
.thumbnail-container {
  width: 100%;
  height: 100%;
  background-color: white;

  .thumbnail-inner {
    width: 100%;
    height: 100%;
    color: black;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(
      to bottom right,
      rgba(26, 125, 140, 0) 30%,
      rgba(26, 125, 140, 0.3) 100%
    );
  }
}

.thumbnail-text {
  font-size: 18px;
  display: inline-block;
  overflow-wrap: break-word;
  border: solid transparent;
  border-width: 30px 0px 30px 0px;
  max-height: 280px !important;
  overflow: hidden;
  padding: 5px;
}

//media queries

.invisible {
  visibility: hidden !important;
}

.hidden-xs-up {
  display: none !important;
}

ion-toast.custom-toast {
  --max-height: 50vh !important;
}

ion-toast.custom-toast::part(message) {
  max-height: calc(50vh - 28px) !important;
  overflow-y: auto !important;
  overflow-wrap: anywhere;
  height: 0px;
}

@media (max-width: 575px) {
  .hidden-xs-down {
    display: none !important;
  }
  .nGY2Viewer {
    margin-top: env(safe-area-inset-top);
    height: calc(100% - env(safe-area-inset-top)) !important;
  }
  .nGY2ViewerToolsTopLeft {
    margin-top: 14%;
  }
  .alert-wrapper {
    min-width: 280px !important;
  }
  ion-modal.sharing-details {
    padding-top: env(safe-area-inset-top) !important;
  }
  ion-modal.swiper-modal {
    padding-top: env(safe-area-inset-top) !important;
  }
  ion-modal.account-modal {
    padding-top: env(safe-area-inset-top) !important;
  }
  ion-modal.reminder-modal {
    padding-top: env(safe-area-inset-top) !important;
  }
  .home-menu {
    margin-top: env(safe-area-inset-top) !important;
  }
  .select-options .alert-wrapper {
    font-size: 1.1rem !important;
  }
  .confirm-action-alert .alert-wrapper .sc-ion-alert-ios {
    font-size: 1.1rem !important;
  }
  .relabel-album .alert-wrapper .sc-ion-alert-ios {
    input {
      font-size: 1rem;
    }
  }
  ion-modal.add-to-album {
    --border-radius: 8px;
  }
  .sharing-details div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .sharing-details object {
    max-width: inherit;
    max-height: auto;
    display: flex;
    overflow: hidden;
  }
  ion-modal.add-to-current-album {
    padding-top: env(safe-area-inset-top) !important;
  }
  ion-button.md.toolbar-buttons {
    width: 32px !important;
    height: 36px !important;
    padding: 4% !important;
  }
  ion-button.md.toolbar-buttons::part(native) {
    padding-inline: 0px !important;
  }
  ion-button.md.toolbar-buttons-share {
    padding: 4% !important;
    font-size: 0.8rem;
  }
  ion-button.md.toolbar-buttons-share::part(native) {
    padding-inline: 0px !important;
  }
}

@media (max-width: 991px) and (min-width: 481px) {
  ion-modal {
    padding-top: env(safe-area-inset-top) !important;
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
  }
  .confirm-action-alert .alert-wrapper .sc-ion-alert-ios {
    font-size: 1.1rem !important;
  }
  .relabel-album .alert-wrapper .sc-ion-alert-ios {
    input {
      font-size: 1rem;
    }
  }
  .select-options .alert-wrapper {
    font-size: 1.1rem !important;
  }
  .nGY2Viewer {
    margin-left: env(safe-area-inset-left);
    margin-right: env(safe-area-inset-right);
    width: calc(
      100% - env(safe-area-inset-left) - env(safe-area-inset-right)
    ) !important;
    margin-top: env(safe-area-inset-top);
    height: calc(100% - env(safe-area-inset-top)) !important;
  }
  .nGY2ViewerToolsTopLeft {
    margin-top: 8%;
  }
  .alert-wrapper {
    min-width: 280px !important;
  }
  // ion-modal.share-modal {
  //   max-height: 80%;
  //   overflow: hidden;
  // }
  ion-modal.sharing-details {
    padding-top: env(safe-area-inset-top) !important;
    padding-left: env(safe-area-inset-left) !important;
    padding-right: env(safe-area-inset-right) !important;
  }
  ion-modal.swiper-modal {
    padding-top: env(safe-area-inset-top) !important;
    padding-left: env(safe-area-inset-left) !important;
    padding-right: env(safe-area-inset-right) !important;
  }
  ion-modal.account-modal {
    padding-top: env(safe-area-inset-top) !important;
    padding-left: env(safe-area-inset-left) !important;
    padding-right: env(safe-area-inset-right) !important;
  }
  ion-modal.reminder-modal {
    padding-top: env(safe-area-inset-top) !important;
    padding-left: env(safe-area-inset-left) !important;
    padding-right: env(safe-area-inset-right) !important;
  }
  ion-split-pane {
    display: flex;
    justify-content: center;
  }
  ion-content.safe-area-padding {
    max-width: calc(
      100% - env(safe-area-inset-left) - env(safe-area-inset-right)
    );
  }
  .context-menu-list > ion-item::part(native) {
    padding: 0px !important;
    margin-left: 4%;
    // --inner-padding-end: 0px !important;
    // --inner-padding-start: 0px !important;
    // --inner-padding-top: 0px !important;
    // --inner-padding-bottom: 0px !important;
  }
  // .context-menu-list > ion-item::part(detail-icon) {
  //   padding: 0px !important;
  //   // --inner-padding-end: 0px !important;
  //   // --inner-padding-start: 0px !important;
  //   // --inner-padding-top: 0px !important;
  //   // --inner-padding-bottom: 0px !important;
  // }
  // ion-item::part(native)::div {
  //   padding: 0px !important;
  // }
}

@media (min-width: 576px) {
  .hidden-sm-up {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .hidden-sm-down {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .hidden-md-up {
    display: none !important;
  }
}

@media (max-width: 991px) {
  .hidden-md-down {
    display: none !important;
  }
}

@media (min-width: 992px) {
  ion-popover.caption-popover {
    --width: fit-content;
    --offset-x: -45px !important;
  }
  .hidden-lg-up {
    display: none !important;
  }
}

@media (max-width: 1199px) {
  .hidden-lg-down {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .hidden-xl-up {
    display: none !important;
  }
}

.hidden-xl-down {
  display: none !important;
}

.popper {
  background-color: #faf9f6;
  color: var(--ion-text-color);
  border-color: var(--ion-text-color);
}

// https://stackoverflow.com/a/76381282
ion-popover [popover]:not(:popover-open):not(dialog[open]) {
  display: contents;
}

ion-popover.caption-popover {
  --width: fit-content;
  text-wrap: nowrap;
  // --offset-x: -45px !important;
}

.lightbox-popover {
  background-color: #faf9f6;
  opacity: 0;
  position: absolute;
  padding: 0.25rem;
  margin-top: 0.5rem;
  display: flex;
  justify-content: center;
  font-size: 1rem;
  border: 1px solid gray;
  border-radius: 3px;
  box-sizing: border-box;
  text-shadow: none;
  z-index: 99999999;
  color: var(--ion-text-color);
}

.thumbnail-popover {
  background-color: #faf9f6;
  opacity: 0;
  position: absolute;
  padding: 0.25rem;
  margin-top: -0.25rem;
  display: flex;
  justify-content: center;
  font-size: 0.75rem;
  border: 1px solid gray;
  border-radius: 3px;
  box-sizing: border-box;
  text-shadow: none;
  z-index: 99999999;
  color: var(--ion-text-color);
}

.selectedAsset {
  border-style: solid !important;
  border-width: 5px !important;
  border-color: var(--ion-color-primary-tint) !important;
}

.popover-hover:hover {
  visibility: hidden;
}

.popover-hover {
  opacity: 1;
}

//adjust custom in icons in lightbox pushing popover slightly lower than standard icons
.lightbox-icon {
  margin-bottom: -0.25rem;
}

.color-primary {
  color: var(--ion-color-primary);
}

.text-selectable {
  user-select: text !important;
}

.sb-overlay h3 {
  color: black;
}

.swiper-modal div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.swiper-modal img {
  max-height: 100%;
  display: flex;
}

.account-modal div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.reminder-modal div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

share-buttons-popup share-buttons {
  display: block !important;
}
share-buttons-popup share-buttons::before {
  content: "Your share link is ready. Use the buttons below to copy or share it.";
  color: black;
  display: flex;
  font-size: 1rem;
  margin: 0.2rem 0.6rem 1rem 0.6rem;
}

.share-card {
  ion-item {
    --inner-padding-bottom: 0;
    --padding-bottom: 0;
  }
}

ion-icon.icon-xl {
  font-size: 48px;
}

ion-card.info-card {
  // height: 90%;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  background-color: transparent;
  margin: 0;
  align-self: stretch;
  flex-grow: 1;
  h2 {
    font-size: 24px;
  }

  .item-inner {
    border: 0;
  }

  ion-thumbnail {
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 0;
  }

  .expand {
    align-items: flex-start;

    @media (min-width: 576px) {
      flex-grow: 1;
    }

    @media (min-width: 767px) {
      // min-height: 6rem;
    }

    @media (min-width: 991px) {
      // min-height: 5.5rem;
    }

    @media (min-width: 1200px) {
      // min-height: 5rem;
    }
  }

  ion-icon,
  ion-label {
    margin: 0;
  }

  ion-button {
    width: 100%;
  }

  ion-item::part(native) {
    padding-inline-start: 0;
    padding-inline-end: 0;
    height: 100%;

    .item-inner {
      padding-inline-start: 0;
      padding-inline-end: 0;
    }
  }
}

.input-wrapper {
  align-items: center !important;
}
